import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';

import './App.scss';

import { Paths } from './constants';
import { Article, Home, Theme } from './pages';
import { ScrollToTop } from './components/scrollToTop/ScrollToTop';
import { User } from './types';
import { UserContext } from './UserContext';
import { useLoadPageData } from './hooks/useLoadPageData';
import ReactGA from 'react-ga';
import { Location } from 'history';

const GA_UA = process.env.REACT_APP_GA_UA;
export const isProduction = process.env.NODE_ENV === 'production';

if (GA_UA) {
  ReactGA.initialize(GA_UA, { debug: !isProduction });
}

export const App = () => {
  const { data: user } = useLoadPageData<User>('auth-info');

  return (
    <UserContext.Provider value={user}>
      <Router>
        <RouterContent />
      </Router>
    </UserContext.Provider>
  );
};

const handleLocationChange = (location: Location<any>) => {
  if (GA_UA) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }
};

const RouterContent = () => {
  const { listen, location } = useHistory();

  useEffect(() => {
    const unlisten = listen(handleLocationChange);
    handleLocationChange(location);
    return () => {
      unlisten();
    };
  }, []);

  return (
    <React.Fragment>
      <ScrollToTop />
      <Switch>
        <Route path={Paths.Article} key="article">
          <Article />
        </Route>
        <Route path={Paths.Theme} key="theme">
          <Theme />
        </Route>
        <Route path={Paths.Home} key="home">
          <Home />
        </Route>
      </Switch>
    </React.Fragment>
  );
};
