import React, { memo } from 'react';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import { hasTouchSupport } from '../../utils';
import { HotTheme } from '../../types';

import style from './HotThemes.module.scss';
import { GA } from '../../constants';

const maxOnelinerLength = 168;

const shortenToElipse = (text: string) => {
  const shorten = R.take(maxOnelinerLength, text);
  return shorten + '...';
};

const shortenToMaxSymbols = (text: string) =>
  text.length > maxOnelinerLength ? shortenToElipse(text) : text;

interface ThemePreviewProps {
  theme: HotTheme;
}

const HotThemePreview = ({ theme }: ThemePreviewProps) => (
  <div className={style.themePreview}>
    <div className={style['themePreview__miniHeading']}>Téma</div>
    {hasTouchSupport ? (
      <>
        <h1 className={style['themePreview__title']}>
          <Link
            to={theme.slug}
            onClick={() => {
              ReactGA.event({
                category: GA.categories.homepage,
                action: GA.actions.slider,
                label: theme.slug,
              });
            }}
          >
            {theme.title}
          </Link>{' '}
        </h1>
        <p className={style['themePreview__oneliner']}>
          <Link
            to={theme.slug}
            onClick={() => {
              ReactGA.event({
                category: GA.categories.homepage,
                action: GA.actions.slider,
                label: theme.slug,
              });
            }}
          >
            {shortenToMaxSymbols(theme.oneliner)}
          </Link>
        </p>
      </>
    ) : (
      <>
        <h1 className={style['themePreview__title']}> {theme.title}</h1>
        <p className={style['themePreview__oneliner']}>
          {shortenToMaxSymbols(theme.oneliner)}
        </p>
      </>
    )}
    <div className={style.themePreview__button}>
      <Link
        to={theme.slug}
        className="link-primary"
        onClick={() => {
          ReactGA.event({
            category: GA.categories.homepage,
            action: GA.actions.slider,
            label: theme.slug,
          });
        }}
      >
        Začít číst
      </Link>
    </div>
  </div>
);

export const HotThemePreviewMemo = memo(HotThemePreview);
