/* eslint-disable indent */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';

import { HotTheme } from '../../types';
import {
  HotThemeActiveDuration,
  HotThemeUpdateInterval,
  HotThemeItemProgressUnits,
  HotThemeTransitionInterval,
} from '../../constants';
import { isInternetExplorer } from '../../utils';
import { HotThemePreviewMemo } from './HotThemePreview';
import { HotThemesProgress } from './HotThemesProgress';

import placeholderLarge from '../../assets/images/placeholderLarge.png';
import style from './HotThemes.module.scss';

interface HotThemesProps {
  themes: HotTheme[];
}

const getIndex = (progress: number) =>
  Math.floor((progress / HotThemeActiveDuration) * HotThemeUpdateInterval);

const isAtStart = (progress: number) =>
  progress % HotThemeItemProgressUnits <
  HotThemeTransitionInterval / HotThemeUpdateInterval;

const isAtEnd = (progress: number) =>
  // a little overhead is added here to prevent flickering on Safari
  HotThemeItemProgressUnits - (progress % HotThemeItemProgressUnits) + 2 <=
  HotThemeTransitionInterval / HotThemeUpdateInterval;

export const HotThemes = ({ themes }: HotThemesProps) => {
  // Cumulative progress of animation going trough all the hot themes.
  // it sums up animation rate for shown themes and actual progress of active theme.
  // The state is updated to the value of ref every 'HotThemeUpdateInterval' ms
  const [progress, setProgress] = useState(0);
  const progressRef = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      progressRef.current++;
      if (progressRef.current >= themes.length * HotThemeItemProgressUnits) {
        progressRef.current = 0;
      }
      setProgress(progressRef.current);
    }, HotThemeUpdateInterval);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectTheme = useCallback((themeIndex: number) => {
    progressRef.current = themeIndex * HotThemeItemProgressUnits;
  }, []);

  const activeThemeIndex = getIndex(progress);
  const activeTheme = themes[activeThemeIndex];

  const setToPrevious = useCallback(() => {
    const previousThemeIndex = activeThemeIndex - 1;

    progressRef.current =
      previousThemeIndex < 0
        ? (themes.length - 1) * HotThemeItemProgressUnits
        : previousThemeIndex * HotThemeItemProgressUnits;
  }, [activeThemeIndex, themes.length]);

  const setToNext = useCallback(() => {
    const nextThemeIndex = activeThemeIndex + 1;

    progressRef.current =
      nextThemeIndex > themes.length - 1
        ? 0
        : nextThemeIndex * HotThemeItemProgressUnits;
  }, [activeThemeIndex, themes.length]);

  const swipeHanlders = useSwipeable({
    onSwipedLeft: setToNext,
    onSwipedRight: setToPrevious,
  });

  return (
    <div
      className={style.background}
      // IE cannot handle image update, the style has to be removed for short duration and applied again
      style={
        isInternetExplorer && progress % HotThemeItemProgressUnits < 2
          ? undefined
          : {
              backgroundImage: `linear-gradient(360deg, #0A111E 0%, rgba(10, 17, 30, 0) 100%), url(${
                activeTheme.imageLarge || placeholderLarge
              })`,
            }
      }
    >
      {/* Pre-loading of other bg images */}
      <div className={style.nextBackgrounds}>
        {themes.map((theme) => (
          <div
            key={theme.slug}
            style={{ backgroundImage: `url(${theme.imageLarge}` }}
          ></div>
        ))}
      </div>

      <div
        className={classNames(style['background__overlay'], {
          [style['fade-in']]: isAtStart(progress),
          [style['fade-out']]: isAtEnd(progress),
        })}
        {...swipeHanlders}
      >
        <div className="containerCenter">
          <div className={style.container}>
            <HotThemePreviewMemo theme={activeTheme} />
            <HotThemesProgress
              themes={themes}
              progress={progress}
              selectTheme={selectTheme}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
