export const UrlParams = {
  ThemeSlug: 'themeSlug',
  ArticleId: 'articleId',
};

export const Paths = {
  Home: '/',
  Theme: `/:${UrlParams.ThemeSlug}`,
  Article: `/:${UrlParams.ThemeSlug}/:${UrlParams.ArticleId}`,
};

// Bootstrap breakpoints - these constants shoud match those in variables.scss
// TODO: load directly from scss
export const Screens = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
};

export const Colors = {
  linkColor: '#e82020',
  progressBarBackground: '#D6E1E6',
};

//Time in ms for one theme to be dislpayed in Hot preview
export const HotThemeActiveDuration = 10000;

// Time in ms for Hot themes progress update interval
// lower the number, smoother the transition
export const HotThemeUpdateInterval = 30;

// Number that represents how much of total progress is taken by a single theme
// total_progress = HotThemeItemProgressUnits * number_of_items
export const HotThemeItemProgressUnits =
  HotThemeActiveDuration / HotThemeUpdateInterval;

// Time in ms of fadeIn and fadeOut animations when swithcing themes
export const HotThemeTransitionInterval = 800;

export const GA = {
  categories: {
    homepage: 'HP',
    theme: 'Téma',
    backToTopic: 'Zpět na téma',
    article: 'Detail článku',
  },
  actions: {
    slider: 'Slider',
    recommended: 'Doporučené',
    other: 'Další témata',
    featured: 'Mohlo by vás zajímat',
  },
};

export enum ArticleModuleTypes {
  'gallery' = 'gallery',
  'info_graphic' = 'info_graphic',
  'embedded_video' = 'embedded_video',
}

export const ArticleModuleTypeNames = {
  [ArticleModuleTypes.gallery]: 'Fotogalerie',
  [ArticleModuleTypes.info_graphic]: 'Infografika',
};
