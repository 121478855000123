import React from 'react';
import style from './Infographics.module.scss';
import { Icon, IconColor, IconSize } from '../../../components/icon/Icons';

type InfographicsZoomProps = {
  image: string;
  closeSelf: () => void;
};

export const InfographicsZoom = ({
  image,
  closeSelf,
}: InfographicsZoomProps) => {
  return (
    <div className={style.zoomInfographics} onClick={closeSelf}>
      <div className={style.closeButton}>
        <div className={style.closeButtonWrapper}>
          <Icon type={'close'} color={IconColor.red}/>
        </div>
      </div>
      <img src={image} />
    </div>
  );
};
