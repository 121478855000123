import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import { useViewportSize } from '../../hooks/useViewportSize';
import { Screens } from '../../constants';
import { HomePageTheme, GaEventData } from '../../types';

import placeholder from '../../assets/images/placeholder.png';
import style from './ThemeCard.module.scss';

interface ThemeCardProps {
  item: HomePageTheme;
  gaEventData?: GaEventData;
}

const cardMargin = 12;
const defaultCardWidth = 190;
const defaultCardHeight = 133;
const smallMobileBreakpoint = 360;

/*
  Calculate size of card on small screens so that there is part of next card
  visible at right edge of the screen

*/
const getCardSize = (viewportWidth: number) => {
  if (viewportWidth < Screens.xs) {
    // This calculation works for all screen sizes, it can be used if requirements change
    // const numberOfCards =
    //   Math.round(viewportWidth / (defaultCardWidth + 2 * cardMargin) - 0.8) +
    //   0.8;

    // Number of cards to be displayed on the screen
    const numberOfCards = viewportWidth > smallMobileBreakpoint ? 2.6 : 1.8;

    const cardWidth =
      (viewportWidth - (2 * numberOfCards + 1) * cardMargin) / numberOfCards;

    const cardHeight = (defaultCardHeight / defaultCardWidth) * cardWidth;

    return [cardWidth, cardHeight];
  }
  return [defaultCardWidth, defaultCardHeight];
};

export const ThemeCard = ({ item: theme, gaEventData }: ThemeCardProps) => {
  const { viewportWidth } = useViewportSize();
  const [cardWidth, cardHeight] = useMemo(() => getCardSize(viewportWidth), [
    viewportWidth,
  ]);

  return (
    <Link
      to={`/${theme.slug}`}
      className={style.link}
      onClick={() => {
        gaEventData && ReactGA.event({ ...gaEventData, label: theme.slug });
      }}
    >
      <div
        className={style.container}
        style={{
          backgroundImage: `url(${theme.image || placeholder})`,
          width: cardWidth,
          height: cardHeight,
        }}
      >
        <div className={style.cardGradient}></div>
        <span className={style.cardText}>{theme.title}</span>
      </div>
    </Link>
  );
};
