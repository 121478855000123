import React from 'react';
import * as R from 'ramda';

import style from './VideoDescription.module.scss';
import { Article } from '../../types';
import { getDateAndTime } from '../../utils';

type VideoDescriptionProps = Pick<
  Article,
  'authors' | 'tags' | 'perex' | 'published'
>;

export const VideoDescription = ({
  perex,
  tags,
  authors,
  published,
}: VideoDescriptionProps) => {
  const [date, time] = getDateAndTime(published);

  return (
    <>
      <div className={style.articleMeta}>
        <div className={style.author}>
          <ul className={style.authorsWrapper}>
            {R.map(
              (author) => (
                <li key={author}>{author}</li>
              ),
              authors
            )}
          </ul>
          <div className={style.publishDate}>
            {date} <span>/{time}</span>
          </div>
        </div>
        <div className={style.tags}>
          {R.map(
            (tag) => (
              <span key={tag}>{tag}</span>
            ),
            R.values(tags)
          )}
        </div>
      </div>
      <div className={style.perex}>
        <ul>
          <li>{perex}</li>
        </ul>
      </div>
    </>
  );
};
