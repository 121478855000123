import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import TextTruncate from 'react-text-truncate';
import ReactGA from 'react-ga';

import { Button, ButtonColor } from '../button/Button';
import { FeaturedTheme as FeaturedThemeT } from '../../types';
import { Screens, GA } from '../../constants';
import { useViewportSize } from '../../hooks/useViewportSize';
import placeholderLarge from '../../assets/images/placeholderLarge.png';

import style from './FeaturedTheme.module.scss';

interface FeaturedThemeProps {
  theme: FeaturedThemeT;
}

export const FeaturedTheme = ({ theme }: FeaturedThemeProps) => {
  const { viewportWidth } = useViewportSize();

  return (
    <div className={style.flexContainer}>
      <div className={style.flexItem}>
        <img
          className={style.image}
          src={theme.imageLarge || placeholderLarge}
          alt="Theme"
        />
      </div>
      <div className={classNames(style.flexItem, style.textCol)}>
        <h2 className={style.title}>{theme.title}</h2>
        <div className={style.perex}>
          <TextTruncate
            text={theme.perex}
            line={viewportWidth > Screens.xs ? 3 : 5}
          />
        </div>
        <Link
          to={theme.slug}
          onClick={() => {
            ReactGA.event({
              category: GA.categories.homepage,
              action: GA.actions.featured,
              label: theme.slug,
            });
          }}
        >
          <Button color={ButtonColor.primary}>
            {viewportWidth > Screens.sm ? (
              <>{`Přečíst téma ${theme.title}`}</>
            ) : (
              <>Začít číst</>
            )}
          </Button>
        </Link>
      </div>
    </div>
  );
};
