import React from 'react';
import style from './LoadingScreen.module.scss';

export const LoadingScreen = () => (
  <div className={style.container}>
    <div className={style.loading}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);
