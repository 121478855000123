/* eslint-disable react/prop-types */
import React, { SFC, useRef, useState } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import classNames from 'classnames';
import * as R from 'ramda';
import { useViewportSize } from '../../hooks/useViewportSize';
import { hasTouchSupport } from '../../utils';

import style from './Slider.module.scss';

// From certain width scroll by 3, until then scroll by all visible
const ElementWidth = 140; // This should be outer size of Cards in the slider
const getScrollFromWidth = (width: number) => {
  if (width < 4 * ElementWidth) {
    return 0;
  }
  return 3;
};

const ArrowInner: SFC = ({ children }) => (
  <div className={style['arrow__inner']}>{children}</div>
);

interface ItemKeys {
  id?: string;
  slug?: string;
}

interface ItemProps {
  [key: string]: unknown;
}

export interface SliderWrapperProps<Item> {
  items: Item[];
  renderItem: SFC<{ item: Item }>;
  itemProps?: ItemProps;
  translate?: number;
  gaAction?: string;
}

export interface SliderProps<Item> extends SliderWrapperProps<Item> {
  renderArrowLeft: SFC;
  renderArrowRight: SFC;
  arrowClass?: string;
}

export const Slider = <Item extends ItemKeys>({
  items,
  renderItem: SliderItem,
  itemProps,
  arrowClass = '',
  renderArrowLeft: ArrowLeft,
  renderArrowRight: ArrowRight,
  translate = 0.1,
}: SliderProps<Item>) => {
  const { viewportWidth } = useViewportSize();
  const scrollMenuRef = useRef<ScrollMenu | null>(null);

  const [firstRender, setFirstRender] = useState(true);

  const initOffsetAsMargin = translate && R.includes(items.length, [3, 4]);

  return (
    <div
      className={style.slider}
      style={
        firstRender && initOffsetAsMargin
          ? { marginLeft: translate }
          : undefined
      }
    >
      <ScrollMenu
        key={viewportWidth} // re-render if width changes
        ref={scrollMenuRef}
        data={items.map((item, index) => (
          <SliderItem
            item={item}
            key={item.slug || item.id || index}
            {...itemProps}
          />
        ))}
        arrowLeft={
          <>
            <ArrowInner>
              <ArrowLeft />
            </ArrowInner>
          </>
        }
        arrowRight={
          <>
            <ArrowInner>
              <ArrowRight />
            </ArrowInner>
          </>
        }
        scrollBy={getScrollFromWidth(viewportWidth)}
        hideSingleArrow
        hideArrows
        arrowClass={classNames(style.arrow, arrowClass)}
        arrowDisabledClass={style['arrow--disabled']}
        itemClass={style.item}
        innerWrapperClass={style.menu}
        menuClass={style.sliderRow}
        menuStyle={{
          // Overriding default innline styles of the library
          alignItems: 'stretch',
          flex: '10% 0 10%',
        }}
        inertiaScrolling
        wheel={false}
        translate={initOffsetAsMargin ? undefined : translate}
        alignCenter={false}
        alignOnResize={false}
        dragging={hasTouchSupport}
        onUpdate={() => {
          setFirstRender(false);
        }}
      />
    </div>
  );
};
