import React, { MouseEvent, useRef, useState, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { HeaderBreadcrumbs } from '../../types';
import { Paths, Screens } from '../../constants';
import { HeaderNavigation } from './HeaderNavigation';
import { Breadcrumbs } from '../breadcrumbs/Breadcrumbs';
import { useViewportSize } from '../../hooks/useViewportSize';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import { ReactComponent as LogoHN } from '../../assets/images/HN.svg';

import style from './Header.module.scss';
import { Icon, IconColor } from '../icon/Icons';

const KontextLogoWidth = 216;
const BreadcrumbsMarginBig = 80;
const BreadcrumbsMarginSmall = 30;
const MenuWidth = 118;
interface HeaderProps {
  breadcrumbs?: HeaderBreadcrumbs;
  openAboutModal: (event: MouseEvent) => void;
}

export const Header: React.FC<HeaderProps> = ({
  breadcrumbs,
  openAboutModal,
}: HeaderProps) => {
  const { viewportWidth } = useViewportSize();
  const themeBreadcrumb = breadcrumbs?.theme;
  const scrollPosition = useScrollPosition();

  const [maxBreadcrumbWidth, setMaxBreadcrumbWidth] = useState<{
    theme?: number;
    article?: number;
  }>();

  const headerNavRef = useRef<HTMLDivElement>(null);
  const themeBreadcrumbRef = useRef<HTMLLIElement>(null);

  /**
   * Calculate the remaining space in header and set max width of breadcrumb items
   * to fill the space. First the article breadcrumb text should be hidden and then
   * theme.
   */
  useLayoutEffect(() => {
    if (headerNavRef.current && themeBreadcrumbRef.current) {
      setMaxBreadcrumbWidth({
        theme:
          viewportWidth -
          KontextLogoWidth -
          (viewportWidth > Screens.md
            ? headerNavRef.current.offsetWidth + BreadcrumbsMarginBig
            : MenuWidth + BreadcrumbsMarginSmall),
        article:
          viewportWidth -
          KontextLogoWidth -
          themeBreadcrumbRef.current.offsetWidth -
          (viewportWidth > Screens.md
            ? headerNavRef.current.offsetWidth + BreadcrumbsMarginBig
            : MenuWidth + BreadcrumbsMarginSmall),
      });
    }
  }, [
    themeBreadcrumbRef.current,
    headerNavRef.current,
    viewportWidth,
    breadcrumbs,
  ]);

  return (
    <div
      className={classNames(
        style.container,
        style.header,
        breadcrumbs ? style.showBreadcrumb : style.homepageContainer,
        viewportWidth <= Screens.sm && breadcrumbs && style.headerPhone
      )}
    >
      {viewportWidth > Screens.sm || !breadcrumbs ? (
        <>
          <a href="https://ihned.cz/">
            <LogoHN className={style.headerLogoLink} />
          </a>
          <span className={style.headerLogoIcon}>
            <Icon type={'arrow-right-header'} color={IconColor.raisinBlack} />
          </span>
          {<Link to={Paths.Home} className={style.headerLogo} />}
          {breadcrumbs && (
            <Breadcrumbs
              breadcrumbs={breadcrumbs}
              maxBreadcrumbWidth={maxBreadcrumbWidth}
              ref={themeBreadcrumbRef}
            />
          )}
          <HeaderNavigation
            openAboutModal={openAboutModal}
            ref={headerNavRef}
          />
        </>
      ) : (
        <>
          {scrollPosition < 18 && (
            <div className={style.logoMobile}>
              <a href="https://ihned.cz/">
                <LogoHN className={style.headerLogoLink} />
              </a>
              <span className={style.headerLogoIcon}>
                <Icon
                  type={'arrow-right-header'}
                  color={IconColor.raisinBlack}
                />
              </span>
              <Link to={Paths.Home} className={style.headerLogo} />
            </div>
          )}
          <div
            className={classNames(
              style.headerPhoneContent,
              scrollPosition > 18 && style.headerPhoneContentBottom
            )}
          >
            {breadcrumbs &&
              themeBreadcrumb &&
              (!breadcrumbs.article ? (
                <Link to={Paths.Home} className={style.link}>
                  Zpět
                </Link>
              ) : (
                <Link to={themeBreadcrumb.link} className={style.link}>
                  {themeBreadcrumb.title}
                </Link>
              ))}
            <HeaderNavigation openAboutModal={openAboutModal} />
          </div>
        </>
      )}
    </div>
  );
};
