import React, { useState, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';

import { Icon, IconColor, IconSize } from '../../../components/icon/Icons';

import style from './Gallery.module.scss';
import { IobjectGallery } from '../../../types';

interface ArrowProps {
  onClick: MouseEventHandler;
}

const ArrowLeft = ({ onClick }: ArrowProps) => (
  <div
    className={classNames(style.arrow, style['arrow--left'])}
    onClick={onClick}
  >
    <span className={style.arrowWrapper}>
      <Icon type="arrow-left" color={IconColor.red} size={IconSize.small} />
    </span>
  </div>
);

const ArrowRight = ({ onClick }: ArrowProps) => (
  <div
    className={classNames(style.arrow, style['arrow--right'])}
    onClick={onClick}
  >
    <span className={style.arrowWrapper}>
      <Icon type="arrow-right" color={IconColor.red} size={IconSize.small} />
    </span>
  </div>
);

interface GallerySliderProps {
  images: IobjectGallery['items'];
}

export const GallerySlider = ({ images }: GallerySliderProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () =>
      setActiveIndex(Math.min(images.length - 1, activeIndex + 1)),
    onSwipedRight: () => setActiveIndex(Math.max(0, activeIndex - 1)),
  });

  return (
    <div className={style.slider} {...swipeHandlers}>
      <div className={style.arrowsBox}>
        {activeIndex > 0 && (
          <ArrowLeft onClick={() => setActiveIndex(activeIndex - 1)} />
        )}
        {activeIndex < images.length - 1 && (
          <ArrowRight onClick={() => setActiveIndex(activeIndex + 1)} />
        )}
      </div>
      <div className={style.galleryPager}>
        {activeIndex + 1} / {images.length}
      </div>
      <img
        style={{
          maxWidth: images[activeIndex].large_image.width,
          maxHeight: images[activeIndex].large_image.height,
        }}
        src={images[activeIndex].large_image.url}
      ></img>
      <div
        className={style.galleryItemDescr}
        style={{
          maxWidth: images[activeIndex].large_image.width,
          maxHeight: images[activeIndex].large_image.height,
        }}
      >
        <div>
          {images[activeIndex].image_title}
          <div className={style.author}>
            Autor / zdroj: {images[activeIndex].author}
          </div>
        </div>
      </div>
    </div>
  );
};
