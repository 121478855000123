import React, { useState } from 'react';

import { InfographicsZoom } from './InfographicsZoom';

import { ArticleImage } from '../../../types';
import { ImgPreview } from '../common/imgPreview/ImgPreview';
import { ArticleModuleTypes, ArticleModuleTypeNames } from '../../../constants';
import { Divider } from '../common/divider/Divider';

interface InfographicsComponentProps {
  image: ArticleImage;
  previewImage: ArticleImage;
  title?: string;
}

export const InfographicsComponent = ({
  image,
  previewImage,
  title,
}: InfographicsComponentProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Divider title={ArticleModuleTypeNames[ArticleModuleTypes.info_graphic]}>
        <ImgPreview
          onClick={() => {
            setModalOpen(true);
          }}
          image={previewImage}
          type={ArticleModuleTypeNames[ArticleModuleTypes.info_graphic]}
          title={title}
        />
      </Divider>
      {modalOpen && (
        <InfographicsZoom
          image={image.url}
          closeSelf={() => {
            setModalOpen(false);
          }}
        />
      )}
    </>
  );
};
