import { useState, useEffect, useCallback } from 'react';

export const useViewportSize = () => {
  const [size, setSize] = useState({
    viewportWidth: window.innerWidth,
    viewportHeight: window.innerHeight,
  });

  const setViewPortSize = useCallback(() => {
    setSize({
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setViewPortSize);
    return () => {
      window.removeEventListener('resize', setViewPortSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return size;
};
