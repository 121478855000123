import React, { useLayoutEffect, useEffect } from 'react';
import * as R from 'ramda';

import { Article } from '../../types';
import { makeIobjectPortals } from '../../articleModules/makeIobjectPortals';

interface ArticleBodyProps {
  articleBody: Article['body'];
  iobjects: Article['iobjects'];
}

const evalAllScripts = (articleBody: string) => {
  const article = document.createElement('div');
  article.innerHTML = articleBody;

  const scripts = article.querySelectorAll('script');

  scripts.forEach((script) => {
    eval(script.innerHTML);
  });
};

export const ArticleBody = ({ articleBody, iobjects }: ArticleBodyProps) => {
  useEffect(() => {
    // If the article contains any tweets, it is loaded properly calling this function
    // twttr object is loaded in index.html
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      window.twttr.widgets.load();
    } catch (error) {
      console.error('Unable to load Tweet', error);
    }
  }, [articleBody]);

  useLayoutEffect(() => {
    evalAllScripts(articleBody);
  }, [articleBody]);

  const iobjectsPortals = iobjects && makeIobjectPortals(R.values(iobjects));

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: articleBody,
        }}
      />
      {iobjectsPortals}
    </>
  );
};
