/* eslint-disable indent */
import React, { useEffect } from 'react';
import classNames from 'classnames';

import { HomePageData } from '../types';
import { Layout } from '../components/layout/Layout';
import { HomePageSlider } from '../components/slider/HomePageSlider';
import { HotThemes } from '../components/hotThemes/HotThemes';
import { FeaturedTheme } from '../components/featuredTheme/FeaturedTheme';
import { ThemeCard } from '../components/themeCard/ThemeCard';
import { Footer } from '../components/footer/Footer';
import { splitToHalves } from '../utils';
import { withPageData } from '../withPageData';

import style from './Home.module.scss';
import { GA } from '../constants';
import { publishHomePageEvent } from '../lib/exponea';

interface HomePageProps {
  pageData: HomePageData;
}

export const HomePage = ({ pageData }: HomePageProps) => {
  const otherThemesHalves = splitToHalves(pageData.otherThemes);

  useEffect(() => {
    publishHomePageEvent();
  }, [pageData]);

  return (
    <Layout>
      <div className={style.background}>
        <div className={style.intro}>
          <HotThemes themes={pageData.hot} />
        </div>
        <div className="containerCenter">
          <div className={style.homeBody}>
            <div
              className={classNames(
                style.row__heading,
                style.containerFullWidth
              )}
            >
              Doporučené
            </div>
            <div
              className={classNames(
                style.row,
                style.containerFullWidth,
                style.containerSliders
              )}
            >
              <HomePageSlider
                items={pageData.popular}
                renderItem={ThemeCard}
                itemProps={{
                  gaEventData: {
                    category: GA.categories.homepage,
                    action: GA.actions.recommended,
                  },
                }}
              />
            </div>
            <div
              className={classNames(
                style.row__heading,
                style.row__featuredTheme,
                style.paddingBottom32,
                style.containerFullWidth
              )}
            >
              Mohlo by vás zajímat
            </div>
            <div
              className={classNames(style.container, style.containerFullWidth)}
            >
              <div className={style.row}>
                <FeaturedTheme theme={pageData.featured} />
              </div>
            </div>
            <div
              className={classNames(
                style.row__heading,
                style.containerFullWidth
              )}
            >
              Další témata
            </div>
            <div
              className={classNames(
                style.row,
                style.containerFullWidth,
                style.containerSliders
              )}
            >
              <div className={style.marginBottom40}>
                <HomePageSlider
                  items={otherThemesHalves[0]}
                  renderItem={ThemeCard}
                  itemProps={{
                    gaEventData: {
                      category: GA.categories.homepage,
                      action: GA.actions.other,
                    },
                  }}
                />
              </div>
              <HomePageSlider
                items={otherThemesHalves[1]}
                renderItem={ThemeCard}
                itemProps={{
                  gaEventData: {
                    category: GA.categories.homepage,
                    action: GA.actions.other,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export const Home = withPageData<HomePageData>(HomePage, 'homepage');
