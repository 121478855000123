import React from 'react';

import { Layout } from '../components/layout/Layout';
import { LoadingScreen } from '../components/loadingScreen/LoadingScreen';

export const Loading = () => (
  <Layout>
    <LoadingScreen />
  </Layout>
);
