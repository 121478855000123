import React from 'react';

import { Slider, SliderWrapperProps } from './Slider';
import { Icon, IconColor, IconSize } from '../icon/Icons';

import style from './HomePageSlider.module.scss';

const LeftArrow = () => (
  <Icon type="arrow-left" color={IconColor.white} size={IconSize.small} />
);
const RightArrow = () => (
  <Icon type="arrow-right" color={IconColor.white} size={IconSize.small} />
);

export const HomePageSlider = <Item,>(
  sliderProps: SliderWrapperProps<Item>
) => (
  <Slider
    {...sliderProps}
    arrowClass={style.arrow}
    renderArrowLeft={LeftArrow}
    renderArrowRight={RightArrow}
  />
);
