import React from 'react';
import style from './Gallery.module.scss';

import { Icon, IconColor } from '../../../components/icon/Icons';
import { GallerySlider } from './GallerySlider';
import { IobjectGallery } from '../../../types';

interface GalleryModalProps {
  images: IobjectGallery['items'];
  closeSelf: () => void;
}

export const GalleryModal = ({ images, closeSelf }: GalleryModalProps) => {
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.closeButton} onClick={closeSelf}>
          <div className={style.closeButtonWrapper}>
            <Icon type={'close'} color={IconColor.red} />
          </div>
        </div>
        <GallerySlider images={images} />
      </div>
    </div>
  );
};
