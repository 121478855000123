import { useState, useEffect, useCallback } from 'react';

export const useScrollPosition = () => {
  const [position, setPosition] = useState(0);

  const setScrollPosition = useCallback(() => {
    setPosition(document.body.scrollTop);
  }, []);

  useEffect(() => {
    document.body.addEventListener('scroll', setScrollPosition);
    return () => {
      document.body.removeEventListener('scroll', setScrollPosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return position;
};
