import * as R from 'ramda';

const rejectNil = R.reject(R.isNil);

export const publish = (event: string, data: object) => {
  if (process.env.REACT_APP_EXPONEA_ENABLE === 'true') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.exponea.track('kt-' + event, rejectNil(data));
  } else {
    console.log('Would publish exponea event', event, rejectNil(data));
  }
};

const getBaseData = () => ({
  ['logged in']: !!document.cookie.match(/user__name=(.*?);/),
  ['medium_name']: 'HN Kontext',
});

const getUserData = (userSubscription?: boolean) => ({
  ['paywall shown']: !userSubscription,
  ['pawall type']: userSubscription ? 'hard' : undefined,
});

export const publishHomePageEvent = () => {
  publish('view homepage', {
    ...getBaseData(),
    title: 'Homepage',
  });
};

export const publishThemeEvent = (
  title: string,
  description: string,
  userSubscription?: boolean
) => {
  publish('view theme', {
    ...getBaseData(),
    ...getUserData(userSubscription),
    title,
    description,
    url: window.location.href,
  });
};

type ArticleData = {
  title: string;
  author: string;
  id: string;
  tags: string[];
  description: string;
  ['published time']: string;
};

export const publishArtEvent = (
  articleData: ArticleData,
  userSubscription?: boolean
) => {
  publish('view article', {
    ...getBaseData(),
    ...getUserData(userSubscription),
    ...articleData,
    url: window.location.href,
  });
};
