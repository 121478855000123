/* eslint-disable react/display-name */
import React, {
  useState,
  MouseEvent,
  useEffect,
  useContext,
  forwardRef,
} from 'react';
import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import { Icon, IconColor, IconSize } from '../icon/Icons';
import { Button, ButtonColor } from '../button/Button';
import { Paths, Screens } from '../../constants';
import { UserContext } from '../../UserContext';

import style from './Header.module.scss';
import { useViewportSize } from '../../hooks/useViewportSize';

const UserCookies = [
  'user__aamhash',
  'user__has_ek',
  'user__has_hn',
  'user__id',
  'user__image',
  'user__login',
  'user__login_id',
  'user__login_security',
  'user__name',
  'user__subscriptions',
];

const deleteUserCookies = () => {
  UserCookies.forEach((cookie) => {
    document.cookie = `${cookie}= ; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.ihned.cz; `;
  });

  window.location.reload();
};

interface HeaderNavigationProps {
  openAboutModal: (event: MouseEvent) => void;
}

export const HeaderNavigation = forwardRef<
  HTMLDivElement,
  HeaderNavigationProps
>(({ openAboutModal }: HeaderNavigationProps, ref) => {
  const [isOpenMenu, setOpenMenu] = useState(false);

  const location = useLocation();
  useEffect(() => {
    setOpenMenu(false);
  }, [location]);

  useEffect(() => {
    document.body.style.overflowY = isOpenMenu ? 'hidden' : 'auto';
  }, [isOpenMenu]);

  const userInfo = useContext(UserContext);

  const { viewportWidth } = useViewportSize();

  return (
    <>
      <div
        className={classNames(
          isOpenMenu && style.headerNavigationOpen,
          style.headerNavigation
        )}
        ref={ref}
      >
        <ul>
          <li className={style.active}>
            <a onClick={openAboutModal} href="#">
              O Kontextu
            </a>
          </li>
          {viewportWidth <= Screens.md && userInfo?.authorized && (
            <li>
              <a href="javascript:void(0)" onClick={deleteUserCookies}>
                Odhlásit se
              </a>
            </li>
          )}
          <li className={style.login}>
            {userInfo?.authorized ? (
              <span className={style.user}>
                <Icon
                  type={'face'}
                  color={IconColor.raisinBlack}
                  size={IconSize.medium}
                />
                <span className={style.userName}>{userInfo?.user.name}</span>
                {viewportWidth > Screens.md && (
                  <span className={style.logoutButton}>
                    <Icon
                      type="logout"
                      color={IconColor.raisinBlack}
                      size={16}
                      onClick={deleteUserCookies}
                    />
                  </span>
                )}
              </span>
            ) : (
              <a
                href={`https://ucet.ihned.cz/?redirect=${process.env.REACT_APP_REDIRECT_TOKEN}`}
                className={style.user}
              >
                <Icon
                  type={'face'}
                  color={IconColor.raisinBlack}
                  size={IconSize.small}
                />
                <span className={style.userName}>Přihlásit se</span>
              </a>
            )}
          </li>
        </ul>
      </div>
      <span className={style.toggleButton}>
        <Button
          color={ButtonColor.link}
          onClick={() => setOpenMenu(!isOpenMenu)}
        >
          <span className={style.toggleIcon}>
            <Icon
              type={isOpenMenu ? 'close' : 'bars'}
              size={IconSize.medium}
              color={IconColor.raisinBlack}
            />
            <span className={style.toggleText}>
              {isOpenMenu ? 'Zavřít' : 'Menu'}
            </span>
          </span>
        </Button>
      </span>
    </>
  );
});
