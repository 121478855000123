import * as R from 'ramda';

export const splitToHalves = <T extends {}>(list: T[]): [T[], T[]] =>
  R.splitAt(Math.ceil(list.length / 2), list);

export const formatDate = (date: string) => {
  // IE cannot handle the original date format "YYYY-MM-DD HH:MM" so only first part is taken
  const dateWithoutTime = date.slice(0, 10);

  return new Intl.DateTimeFormat('cs', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }).format(new Date(dateWithoutTime));
};

// Remove time from date with format YYYY-MM-DD HH-MM-SS
export const removeTime = (date: string) => date.split(' ')[0];

export const isInternetExplorer =
  navigator.userAgent.indexOf('MSIE') !== -1 ||
  navigator.appVersion.indexOf('Trident/') > -1;

export const hasTouchSupport =
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0
    ? true
    : false;

export const getReadTimeText = (readTime: number) =>
  R.o(
    R.cond<number, string>([
      [R.equals(1), R.always('1 minuta')],
      [R.gt(5), (time) => `${time} minuty`],
      [R.T, (time) => `${time} minut`],
    ]),
    Math.ceil
  )(readTime);

export const htmlDecode = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};
export const getDateAndTime = (dateTime: string) => {
  const dateTimeObj = new Date(dateTime);
  return [
    Intl.DateTimeFormat('cs', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    }).format(dateTimeObj),
    Intl.DateTimeFormat('cs', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(dateTimeObj),
  ];
};
