import React from 'react';
import { InfographicsComponent } from './InfographicsComponent';
import { IobjectInfographics } from '../../../types';

interface InfographicsProps {
  data: IobjectInfographics;
}

export const Infographics = ({ data }: InfographicsProps) => {
  return (
    <InfographicsComponent
      image={data.image}
      previewImage={data.preview_image}
      title={data.name}
    />
  );
};
