import React from 'react';
import classNames from 'classnames';

import style from './LoginBlock.module.scss';

export const LoginBlock = () => (
  <div className={style.container}>
    <div className={style.shadow}></div>
    <h3 className={style.title}>
      Zatím jste si přečetli méně než 10% textu. Pokračování je k dispozici
      pouze pro platící čtenáře.
    </h3>
    <div>
      <div className={classNames(style.box, style.boxFirst)}>
        <h4 className={style.subtitle}>Předplatným získáte:</h4>
        <ul className={style.list}>
          <li>Přístup do Kontextu</li>
          <li>Kompletní prémiový obsah ihned.cz</li>
          <li>Online archiv článků od r. 1995</li>
        </ul>
        <div className={style.price}>
          od <span className={style.priceTitle}>145 Kč</span> / měsíc
        </div>
        <div className={style.bodyButton}>
          <a
            href={'https://ihned.cz/predplatne-hnplus/?utm_source=ihned&utm_medium=paywall-banner&utm_term=economia-weby&utm_campaign=predplatne-hn'}
            className="link-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Získat předplatné
          </a>
        </div>
      </div>
      <div className={classNames(style.box, style.boxSecond)}>
        <h4 className={style.subtitle}>Máte již předplatné?</h4>
        <div className={style.bodyButton}>
          <a
            href={`https://ucet.ihned.cz/?redirect=${process.env.REACT_APP_REDIRECT_TOKEN}`}
            className="link-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Přihlásit se
          </a>
        </div>
      </div>
    </div>
    <div className={style.textClear}></div>
  </div>
);
