import React from 'react';
import { Layout } from '../components/layout/Layout';
import style from './Error.module.scss';

interface ErrorProps {
  errorMessage?: string;
}

export const Error = ({ errorMessage }: ErrorProps) => (
  <Layout>
    <div className={style.notFound}>
      {errorMessage && <h1>{errorMessage}</h1>}
      <p>Něco se pokazilo.</p>
      <p>
        Vypadá to že problém je na naší straně - na nápravě se usilovně pracuje.
      </p>

      <p>Zkuste obnovit stránku, nebo se sem vrátit později.</p>
      <p>
        Články z Kontextu také naleznete na{' '}
        <a href="https://ihned.cz/" target="_blank" rel="noopener noreferrer">
          ihned.cz
        </a>
      </p>
    </div>
  </Layout>
);
