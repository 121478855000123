import React from 'react';

import { Icon, IconColor } from '../../../../components/icon/Icons';
import { ArticleImage } from '../../../../types';
import style from './ImgPreview.module.scss';

interface ImgPreviewProps {
  onClick?: () => void;
  image: ArticleImage;
  type?: string;
  title?: string;
}

export const ImgPreview = ({
  onClick: handleClick,
  image,
  type,
  title,
}: ImgPreviewProps) => {
  return (
    <div className={style.preview}>
      <div onClick={handleClick} className={style.imageContainer}>
        <img
          src={image.url}
          className={style.image}
          style={{ width: image.width}}
        ></img>
      </div>
      <div className={style.galleryComponent}>
        <div className={style.type}>{type}</div>
        <div className={style.title}>{title}</div>
        <button className={style.mediaButton} onClick={handleClick}>
          <Icon type={'image'} color={IconColor.white} />
        </button>
      </div>
    </div>
  );
};
