import React from 'react';

import { Slider, SliderWrapperProps } from './Slider';
import { Icon, IconColor, IconSize } from '../icon/Icons';

import style from './ThemeSlider.module.scss';

const LeftArrow = () => (
  <Icon type="arrow-left" color={IconColor.raisinBlack} size={IconSize.small} />
);
const RightArrow = () => (
  <Icon
    type="arrow-right"
    color={IconColor.raisinBlack}
    size={IconSize.small}
  />
);

export const ThemeSlider = <Item,>(sliderProps: SliderWrapperProps<Item>) => (
  <Slider
    {...sliderProps}
    arrowClass={style.arrow}
    renderArrowLeft={LeftArrow}
    renderArrowRight={RightArrow}
  />
);
