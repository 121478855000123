/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { HeaderBreadcrumbs } from '../../types';

import style from './Breadcrumbs.module.scss';

interface BreadcrumbsProps {
  breadcrumbs: HeaderBreadcrumbs;
  maxBreadcrumbWidth?: { theme?: number; article?: number };
}

export const Breadcrumbs = forwardRef<HTMLLIElement, BreadcrumbsProps>(
  ({ breadcrumbs, maxBreadcrumbWidth }: BreadcrumbsProps, ref) => (
    <div>
      <ol className={style.breadcrumbs}>
        <li
          className={style.breadcrumbItem}
          ref={ref}
          style={{ maxWidth: maxBreadcrumbWidth?.theme }}
        >
          {breadcrumbs.article ? (
            <Link to={breadcrumbs.theme.link}>{breadcrumbs.theme.title}</Link>
          ) : (
            <span>{breadcrumbs.theme.title}</span>
          )}
        </li>
        {breadcrumbs.article && (
          <li
            className={classNames(
              style.breadcrumbItem,
              style.breadcrumbArticle
            )}
            style={{ maxWidth: maxBreadcrumbWidth?.article }}
          >
            {breadcrumbs.article.title}
          </li>
        )}
      </ol>
    </div>
  )
);
