import React, { MouseEventHandler, memo } from 'react';
import { Line } from 'rc-progress';
import classnames from 'classnames';

import { HotTheme } from '../../types';
import { HotThemeItemProgressUnits, Colors, Screens } from '../../constants';
import { isInternetExplorer } from '../../utils';
import style from './HotThemes.module.scss';
import { useViewportSize } from '../../hooks/useViewportSize';

/*
  Returns themes progress in % if it is active, 999 otherwise.

  999 is used to denote inactive theme - this is due to problems with styling in
  the rc-progress library. Incative themes therefore must have full progress bar
  with white color for correct display
*/
const getThemeProgress = (totalProgress: number, index: number) => {
  const themeProgress = totalProgress - index * HotThemeItemProgressUnits;

  const relativeThemeProgress =
    (100 * themeProgress) / HotThemeItemProgressUnits;

  return 0 < relativeThemeProgress && relativeThemeProgress < 100
    ? relativeThemeProgress
    : 999;
};
interface ThemeWithProgressProps {
  theme: HotTheme;
  themeProgress: number;
  onClick: MouseEventHandler;
}

const ThemeWithProgress = ({
  theme,
  themeProgress,
  onClick,
}: ThemeWithProgressProps) => {
  const { viewportWidth } = useViewportSize();

  const isActive = 0 < themeProgress && themeProgress < 100;

  return (
    <div
      className={classnames(style['progressRow__theme'], {
        [style['progressRow__theme--inactive']]: !isActive,
        // IE has hover applied individually since it does not support advanced media queries
        [style['progressRow__theme--ie']]: isInternetExplorer,
      })}
      onClick={onClick}
    >
      <div className={style.progressLineHeight}>
        <Line
          className={style.progressLine}
          strokeWidth={viewportWidth > Screens.xs ? 3 : 5}
          strokeColor={isActive ? Colors.linkColor : '#fff'}
          trailWidth={viewportWidth > Screens.xs ? 3 : 5}
          trailColor={Colors.progressBarBackground}
          percent={themeProgress}
        />
        {viewportWidth > Screens.xs && (
          <div className={style['progressRow__themeName']}>{theme.title}</div>
        )}
      </div>
    </div>
  );
};

const ThemeWithProgressMemo = memo(ThemeWithProgress);

interface HotThemesProgressProps {
  themes: HotTheme[];
  progress: number;
  selectTheme: (themeIndex: number) => void;
}

export const HotThemesProgress = ({
  themes,
  progress,
  selectTheme,
}: HotThemesProgressProps) => (
  <div className={style.progressRow}>
    {themes.map((theme, index) => (
      <ThemeWithProgressMemo
        theme={theme}
        key={theme.slug}
        themeProgress={getThemeProgress(progress, index)}
        onClick={() => selectTheme(index)}
      />
    ))}
  </div>
);
