import React from 'react';
import { GalleryComponent } from './GalleryComponent';

import { IobjectGallery } from '../../../types';

interface GalleryProps {
  data: IobjectGallery;
}

export const Gallery = ({ data }: GalleryProps) => (
  <GalleryComponent
    images={data.items}
    previewImage={data.preview_image}
    previewTitle={data.name}
  />
);
