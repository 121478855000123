import React, { ReactNode, useState } from 'react';

import { HeaderBreadcrumbs } from '../../types';
import { Header } from '../header/Header';
import { AboutModal } from '../aboutModal/AboutModal';

interface LayoutProps {
  children: ReactNode;
  breadcrumbs?: HeaderBreadcrumbs;
}

export const Layout = ({ children, breadcrumbs }: LayoutProps) => {
  const hasVisited = window.localStorage.getItem('hasVisited');
  const [isModalVisible, setModalVisible] = useState(!hasVisited);
  const enableOverflow = (enable: boolean) => {
    document.body.style.overflowY = enable ? 'hidden' : 'auto';
  };

  isModalVisible ? enableOverflow(true) : enableOverflow(false);

  return (
    <div>
      {isModalVisible ? (
        <AboutModal
          hideAboutModal={() => {
            setModalVisible(false);
            window.localStorage.setItem('hasVisited', 'true');
          }}
        />
      ) : (
        <Header
          openAboutModal={() => setModalVisible(true)}
          breadcrumbs={breadcrumbs}
        />
      )}
      {children}
    </div>
  );
};
