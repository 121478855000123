import React, { useState } from 'react';
import classNames from 'classnames';

import { Icon, IconColor, IconSize } from '../icon/Icons';

import style from './ShareArticle.module.scss';

const copyToClipBoard = (text: string) => {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

type ShareArticleProps = {
  title?: string;
};

export const ShareArticle = ({ title }: ShareArticleProps) => {
  const currentUrl = window.location.href;

  const [urlCopied, setUrlCopied] = useState(false);
  const [urlCopyError, setUrlCopyError] = useState(false);

  const copyUrl = () => {
    try {
      copyToClipBoard(currentUrl);
      setUrlCopied(true);
      setTimeout(() => {
        setUrlCopied(false);
      }, 2000);
    } catch (error) {
      setTimeout(() => {
        setUrlCopyError(false);
      }, 2000);
    }
  };

  return (
    <div className={classNames(style.fixedBlockSocial)}>
      Sdílet článek
      <div className={style.fixedBlockSocialIcons}>
        {/* The facebook sharing requires some SSR solution, show this icon after it is implemented */}
        {/* <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Icon
            type="facebook"
            color={IconColor.blueDark}
            size={IconSize.medium}
          />
        </a> */}
        <a
          href={`https://twitter.com/intent/tweet?url=${currentUrl}&text=${title}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Icon
            type="twitter"
            color={IconColor.blueDark}
            size={IconSize.medium}
          />
        </a>
        <span onClick={copyUrl} className="tooltip">
          <Icon
            type="fe-link"
            color={IconColor.blueDark}
            size={IconSize.medium}
          />
          {urlCopied && <span className="tooltipText">Odkaz zkopírován</span>}
          {urlCopyError && (
            <span className="tooltipText">Nepodařilo se zkopírovat odkaz</span>
          )}
        </span>
      </div>
    </div>
  );
};
