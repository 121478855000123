import React, { useMemo } from 'react';
import CSS from 'csstype';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ReactGA from 'react-ga';

import { Theme, ThemePageArticle, GaEventData } from '../../types';
import { formatDate, getReadTimeText } from '../../utils';
import { Screens, GA } from '../../constants';
import { useViewportSize } from '../../hooks/useViewportSize';
import TextTruncate from 'react-text-truncate';

import placeholder from '../../assets/images/placeholder.png';
import style from './articleCard.module.scss';

interface ThemePageArticleCardProps {
  item: { article: ThemePageArticle; themeSlug: Theme['slug'] };
  inlineStyle?: CSS.Properties;
}

export const ThemePageArticleCard = ({
  item: { article, themeSlug },
  inlineStyle,
}: ThemePageArticleCardProps) => {
  const { viewportWidth } = useViewportSize();

  const articleLink = useMemo(() => `/${themeSlug}/${article.id}`, [
    themeSlug,
    article.id,
  ]);

  return (
    <div
      className={classNames(style.card, style.cardResize, 'cards-resposive')}
      style={inlineStyle}
    >
      <Link
        to={articleLink}
        onClick={() => {
          ReactGA.event({
            category: GA.categories.theme,
            action: themeSlug,
            label: articleLink,
          });
        }}
      >
        <div
          className={classNames(
            style.imageContainer,
            style.imageContainerResize
          )}
        >
          <img
            className={style.image}
            src={article.image || placeholder}
            alt={article.title}
          />
        </div>
      </Link>
      <div className={classNames(style.body, style.bodyResize)}>
        <div>
          <span className={style.subtitle}>
            Článek <span className={style.dot}></span>
            {getReadTimeText(article.readingTime)}&nbsp;čtení
          </span>
          <span className={style.subtitlePublished}>
            {formatDate(article.published)}
          </span>
        </div>
        <h3 className={classNames(style.title, style.titleResize)}>
          <Link
            to={articleLink}
            onClick={() => {
              ReactGA.event({
                category: GA.categories.theme,
                action: themeSlug,
                label: articleLink,
              });
            }}
          >
            <TextTruncate
              text={article.title}
              line={viewportWidth > Screens.md ? 2 : 3}
            />
          </Link>
        </h3>
        <div className={style.bodyButton}>
          <Link
            to={articleLink}
            className="link-primary"
            onClick={() => {
              ReactGA.event({
                category: GA.categories.theme,
                action: themeSlug,
                label: articleLink,
              });
            }}
          >
            Přečíst článek
          </Link>
        </div>
      </div>
    </div>
  );
};
