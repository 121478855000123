import React, { MouseEventHandler, useEffect, useState } from 'react';

import { AboutCarousel } from '../aboutCarousel/AboutCarousel';
import { Icon, IconColor } from '../icon/Icons';

import style from './AboutModal.module.scss';

interface AboutModalProps {
  hideAboutModal: MouseEventHandler;
}

export const AboutModal = ({ hideAboutModal }: AboutModalProps) => {
  const [orientationKey, setOrientationKey] = useState(1);

  // Force re-render of modal on orientation change
  const changeOrientationKey = () => {
    setOrientationKey(Math.random());
  };

  useEffect(() => {
    window.addEventListener('orientationchange', changeOrientationKey);
    return () =>
      window.removeEventListener('orientationchange', changeOrientationKey);
  }, []);

  return (
    <div className={style.container}>
      <div className={style.closeButton}>
        <div onClick={hideAboutModal}>
          <Icon type={'close'} color={IconColor.white} />
        </div>
      </div>
      <AboutCarousel hideAboutModal={hideAboutModal} key={orientationKey} />
    </div>
  );
};
