import React, { useState, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';

import { Icon, IconColor, IconSize } from '../icon/Icons';
import { slides } from './Slides';
import { useViewportSize } from '../../hooks/useViewportSize';

import style from './AboutCarousel.module.scss';

interface ArrowProps {
  onClick: MouseEventHandler;
}

const ArrowLeft = ({ onClick }: ArrowProps) => (
  <div
    className={classNames(style.arrow, style['arrow--left'])}
    onClick={onClick}
  >
    <Icon type="arrow-left" color={IconColor.white} size={IconSize.small} />
  </div>
);

const ArrowRight = ({ onClick }: ArrowProps) => (
  <div
    className={classNames(style.arrow, style['arrow--right'])}
    onClick={onClick}
  >
    <Icon type="arrow-right" color={IconColor.white} size={IconSize.small} />
  </div>
);

interface DotProps {
  isActive: boolean;
  onClick: MouseEventHandler;
}

const Dot = ({ isActive, onClick }: DotProps) => (
  <span
    className={classNames(style.dot, { [style['dot--active']]: isActive })}
    onClick={onClick}
  />
);

interface AboutCarouselProps {
  hideAboutModal: MouseEventHandler;
}

export const AboutCarousel = ({ hideAboutModal }: AboutCarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const { viewportWidth } = useViewportSize();

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () =>
      setActiveIndex(Math.min(slides.length - 1, activeIndex + 1)),
    onSwipedRight: () => setActiveIndex(Math.max(0, activeIndex - 1)),
  });

  return (
    <div className={style.slider} {...swipeHandlers} key={viewportWidth}>
      <div className={style.arrowsBox}>
        {activeIndex > 0 && (
          <ArrowLeft onClick={() => setActiveIndex(activeIndex - 1)} />
        )}
        {activeIndex < slides.length - 1 && (
          <ArrowRight onClick={() => setActiveIndex(activeIndex + 1)} />
        )}
      </div>
      <div
        className={style.sliderContent}
        style={{
          transform: `translate(-${activeIndex * viewportWidth}px, -50%)`,
          transition: 'transform ease-out 0.45s',
          width: `${viewportWidth * slides.length}px`,
        }}
      >
        {slides.map((Slide, i) => (
          <div className={style.slide} key={i}>
            <Slide onClick={hideAboutModal} />
          </div>
        ))}
      </div>
      <div className={style.dots}>
        <div className={style['dots__inner']}>
          {slides.map((slide, i) => (
            <Dot
              key={i}
              isActive={i === activeIndex}
              onClick={() => setActiveIndex(i)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
