import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import TextTruncate from 'react-text-truncate';
import ReactGA from 'react-ga';

import { Theme, OtherArticle, GaEventData } from '../../types';
import { formatDate, getReadTimeText } from '../../utils';
import { useViewportSize } from '../../hooks/useViewportSize';
import { Screens } from '../../constants';
import placeholder from '../../assets/images/placeholder.png';

import style from './articleCard.module.scss';

interface ArticlePageArticleCardProps {
  item: { article: OtherArticle; themeSlug: Theme['slug'] };
  gaEventData?: GaEventData;
}

export const ArticlePageArticleCard = ({
  item: { article, themeSlug },
  gaEventData,
}: ArticlePageArticleCardProps) => {
  const { viewportWidth } = useViewportSize();

  const articleLink = useMemo(() => `/${themeSlug}/${article.id}`, [
    themeSlug,
    article.id,
  ]);

  return (
    <div
      className={classNames(
        style.card,
        style.cardSecondVariant,
        'cards-resposive',
        'cards-resposive-smaller-card'
      )}
    >
      <Link
        to={articleLink}
        onClick={() => {
          gaEventData && ReactGA.event({ ...gaEventData, label: articleLink });
        }}
      >
        <div className={style.imageContainer}>
          <img
            className={style.image}
            src={article.image || placeholder}
            alt={article.title}
          />
        </div>
      </Link>
      <div className={style.body}>
        <div>
          <span className={style.subtitle}>
            Článek <span className={style.dot}></span>
            {getReadTimeText(article.readingTime)}&nbsp;čtení
          </span>
          <span className={style.subtitlePublished}>
            {formatDate(article.published)}
          </span>
        </div>
        <Link
          to={articleLink}
          onClick={() => {
            gaEventData &&
              ReactGA.event({ ...gaEventData, label: articleLink });
          }}
        >
          <h3 className={style.title}>
            <TextTruncate
              text={article.title}
              line={viewportWidth > Screens.md ? 2 : 3}
            />
          </h3>
        </Link>
        <div className={style.bodyText}>
          <div className={style.perex}>
            <TextTruncate text={article.perex} line={4} />
          </div>
        </div>
      </div>
    </div>
  );
};
