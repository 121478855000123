import React, { MouseEventHandler } from 'react';
import undrawNewspaper from '../../assets/images/undraw_newspaper.svg';
import undrawReading from '../../assets/images/undraw_reading.svg';
import undrawReviewed from '../../assets/images/undraw_reviewed.svg';
import { Button, ButtonColor } from '../button/Button';

import style from './Slides.module.scss';

interface SlideProps {
  onClick: MouseEventHandler;
}

const SlideOne = () => (
  <div className={style.slideContent}>
    <div className={style.verticalCenter}>
      <h1>Co je HN Kontext?</h1>
      <img src={undrawNewspaper} alt="undrawNewspaper" />
      <p>
        Kauzy, události, příběhy, témata - přehledně a v nejdůležitějších
        bodech, spolu s výběrem zásadních článků, které v HN vyšly.
      </p>
    </div>
  </div>
);

const SlideTwo = () => (
  <div className={style.slideContent}>
    <div className={style.verticalCenter}>
      <h1>Jak vybíráme témata</h1>
      <img src={undrawReviewed} alt="undrawReviewed" />
      <p>
        Každá událost žije dlouho poté, co zmizí z titulních stránek novin. V
        Kontextu vytahujeme to podstatné znovu na světlo a ukazujeme, jak
        jednotlivé události dopadly.
      </p>
    </div>
  </div>
);

const SlideThree = ({ onClick }: SlideProps) => (
  <div className={style.slideContent}>
    <div className={style.verticalCenter}>
      <h1>Kdy číst Kontext?</h1>
      <img src={undrawReading} alt="undrawReading" />
      <p>
        Přesto, že vybíráme z článků, které k tématům vyšly, jen ty podstatné a
        zásadní, je to stále velké množství materiálu. Cílem Kontextu je dát vám
        rychlý přehled hlavních momentů každého tématu, a nabídnout možnost
        přečíst si detaily do větší hloubky.
        <div className={style['slide__button']}>
          <Button color={ButtonColor.primary} onClick={onClick}>
            Začít číst Kontext
          </Button>
        </div>
      </p>
    </div>
  </div>
);

export const slides = [SlideOne, SlideTwo, SlideThree];
