import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';

import './icon.scss';

export type IconType =
  | 'arrow-right-header'
  | 'tick'
  | 'arrow-left'
  | 'arrow-left-13'
  | 'arrow-right'
  | 'arrow-right-9'
  | 'arrow-right-8'
  | 'arrow-right-button'
  | 'close'
  | 'face'
  | 'fe-link'
  | 'bars'
  | 'facebook'
  | 'twitter'
  | 'image'
  | 'author'
  | 'logout';

export enum IconSize {
  smaller = 12,
  small = 20,
  medium = 24,
  large = 32,
}
export enum IconColor {
  white = 'white',
  red = 'red',
  blueDarker = 'blue-darker',
  blueDark = 'blue-dark',
  raisinBlack = 'raisin-black',
  blueLighter = 'blue-lighter',
}

interface IconProps {
  type: IconType;
  size?: number;
  color?: IconColor;
  onClick?: MouseEventHandler;
}

export const Icon = (props: IconProps) => {
  const { type, size, color, onClick } = props;

  return (
    <span
      className={classNames({ [`icon-${type}`]: type }, color)}
      style={{ fontSize: size }}
      onClick={onClick}
    >
      {' '}
    </span>
  );
};
