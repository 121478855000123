import React, {
  useRef,
  RefObject,
  useLayoutEffect,
  useState,
  useEffect,
  useContext,
} from 'react';
import { Link } from 'react-router-dom';

import { ThemePageData } from '../types';
import { Screens, UrlParams } from '../constants';
import { useViewportSize } from '../hooks/useViewportSize';
import { ThemeSlider } from '../components/slider/ThemeSlider';
import { ThemePageArticleCard } from '../components/articleCard/ThemePageArticleCard';
import { Layout } from '../components/layout/Layout';
import { withPageData } from '../withPageData';
import { htmlDecode } from '../utils';

import placeholderLarge from '../assets/images/placeholderLarge.png';
import style from './Theme.module.scss';
import { publishThemeEvent } from '../lib/exponea';
import { UserContext } from '../UserContext';

interface ThemePageProps {
  pageData: ThemePageData;
}

const IntroMargin = 34;
const BottomPadding = 50;
const CardHeigth = { max: 377, min: 276 };
// The min values are applied bellow 600px heigth, within
// HeightResizeTreshold the dimensions can be even smaller
const CardWidth = { max: 331, min: 242 };
const FontSize = { max: 14, min: 12 };
const LineHeight = { max: 1.429, min: 1.286 };

const enableOverflow = (enable: boolean) => {
  document.body.style.overflowY =
    enable || window.innerWidth < Screens.lg ? 'auto' : 'hidden';
};

/**
 * Calculate size of cards to fit the remaining space of the screen under the
 * introRef element
 */
const updateCardSize = (
  viewportHeight: number,
  introRef: RefObject<HTMLDivElement>
) => {
  const introBottom = introRef.current?.getBoundingClientRect().bottom;

  if (introBottom) {
    const remainingHeight =
      viewportHeight - introBottom - IntroMargin - BottomPadding;

    if (remainingHeight < CardHeigth.min) {
      enableOverflow(true);
      return {
        width: CardWidth.min,
        fontSize: FontSize.min,
        lineHeight: LineHeight.min,
      };
    } else if (
      remainingHeight >= CardHeigth.min &&
      remainingHeight < CardHeigth.max
    ) {
      enableOverflow(false);
      return {
        width: (remainingHeight * CardWidth.max) / CardHeigth.max,
        fontSize: (remainingHeight * FontSize.max) / CardHeigth.max,
        lineHeight: LineHeight.max,
      };
    }
    enableOverflow(false);
  }
};

export const ThemePage = ({ pageData }: ThemePageProps) => {
  const { viewportWidth, viewportHeight } = useViewportSize();

  const [cardWidth, setCardWidth] = useState<{
    height?: number;
    width?: number;
    fontSize?: number;
    lineHeight?: number;
  }>();

  const introRef = useRef<HTMLDivElement>(null);

  const userInfo = useContext(UserContext);

  useEffect(() => {
    publishThemeEvent(
      pageData.title,
      pageData.perex,
      userInfo?.activeSubscription
    );
  }, [pageData, userInfo]);

  useLayoutEffect(() => {
    const cardSize = updateCardSize(viewportHeight, introRef);
    setCardWidth(cardSize);
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [viewportHeight, viewportWidth]);

  return (
    <Layout
      breadcrumbs={pageData && { theme: { title: pageData.title, link: '#' } }}
    >
      {pageData ? (
        <div>
          <div
            className={style.background}
            style={{
              backgroundImage:
                viewportWidth >= Screens.md
                  ? `url(${pageData.imageLarge || placeholderLarge})`
                  : undefined,
            }}
          >
            <div className={style.gradient} />
          </div>
          <div className={style.container}>
            <div className={style.intro} ref={introRef}>
              {viewportWidth > Screens.sm && (
                <div>
                  <Link to="/" className={style.link}>
                    zpět
                  </Link>
                </div>
              )}
              <h2 className={style.title}>{pageData.title}</h2>
              <p className={style.perex}>{htmlDecode(pageData.perex)}</p>
            </div>
            {viewportWidth >= Screens.lg && (
              <div className={style.themeSlider}>
                <ThemeSlider
                  items={pageData.articles.map((article) => ({
                    article,
                    themeSlug: pageData.slug,
                  }))}
                  renderItem={ThemePageArticleCard}
                  itemProps={{
                    inlineStyle: cardWidth,
                  }}
                />
              </div>
            )}
          </div>
          {viewportWidth < Screens.lg && (
            <div className="containerCards">
              <div className="cards">
                {pageData.articles.map((article) => (
                  <ThemePageArticleCard
                    key={article.id}
                    item={{ article, themeSlug: pageData.slug }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>Téma nenalezeno</div>
      )}
    </Layout>
  );
};

export const Theme = withPageData<ThemePageData>(
  ThemePage,
  `theme/:${UrlParams.ThemeSlug}`
);
