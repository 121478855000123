/* eslint-disable indent */
import React, { ComponentType } from 'react';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';

import { useLoadPageData } from './hooks/useLoadPageData';
import { Loading, Error as ErrorPage } from './pages';

const isUrlParam = R.startsWith(':');

type PageProps<T> = {
  pageData: T;
};

/**
 * HOC that loads page data and displays page only when it is available
 *
 * @param Page  a react component to be rendered, it should be a complete page
 * @param endpoint an endpoint to fetch data from
 * @param urlParams a key optional endpoint parameter to be retreived from url
 */
export const withPageData = <T,>(
  Page: ComponentType<PageProps<T>>,
  urlPath: string
) => () => {
  const params = useParams<{ [key: string]: string }>();

  const urlParts = R.split('/', urlPath);

  const address = urlParts.reduce((path, pathSegment) => {
    const segmentValue = R.when(
      isUrlParam,
      (param) => params[R.tail(param)],
      pathSegment
    );

    return `${path}/${segmentValue}`;
  });

  const { isFetchingPageData, data, error } = useLoadPageData<T>(address);

  if (isFetchingPageData) {
    return <Loading />;
  } else if (data && !error?.hasError) {
    return <Page pageData={data} />;
  }
  return <ErrorPage errorMessage={error?.errorMessage} />;
};
