import React, { useState } from 'react';
import { GalleryModal } from './GalleryModal';

import style from './Gallery.module.scss';
import { IobjectGallery } from '../../../types';
import { ImgPreview } from '../common/imgPreview/ImgPreview';
import { ArticleModuleTypes, ArticleModuleTypeNames } from '../../../constants';
import { Divider } from '../common/divider/Divider';

interface GalleryComponentProps {
  images: IobjectGallery['items'];
  previewImage: IobjectGallery['preview_image'];
  previewTitle: IobjectGallery['name'];
}

export const GalleryComponent = ({
  images,
  previewImage,
  previewTitle,
}: GalleryComponentProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Divider title={ArticleModuleTypeNames[ArticleModuleTypes.gallery]}>
        <div className={style.gallery}>
          <ImgPreview
            onClick={() => {
              setModalOpen(true);
            }}
            image={previewImage}
            type={ArticleModuleTypeNames[ArticleModuleTypes.gallery]}
            title={previewTitle}
          />
        </div>
      </Divider>
      {modalOpen && (
        <GalleryModal
          images={images}
          closeSelf={() => {
            setModalOpen(false);
          }}
        />
      )}
    </>
  );
};
