import { useEffect, useState } from 'react';

type Error = {
  hasError: boolean;
  errorMessage?: string;
};

type PageData<T> = {
  data?: T;
  isFetchingPageData: boolean;
  error?: Error;
};

export const useLoadPageData = <T>(endpoint: string) => {
  const [pageData, setPageData] = useState<PageData<T>>({
    isFetchingPageData: true,
  });

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_PATH}/${endpoint}`
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`${response.status}: Nepodařilo se načíst obsah`);
        }

        return response.json();
      })
      .then((data) => {
        setPageData({ data: data, isFetchingPageData: false });
      })
      .catch((error) => {
        setPageData({
          isFetchingPageData: false,
          error: { hasError: true, errorMessage: error.message },
        });

        console.error(error);
      });
  }, [endpoint]);

  return pageData;
};
