import React, { MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';

import './Button.scss';

export enum ButtonColor {
  primary = 'primary',
  link = 'link',
}

interface ButtonProps {
  onClick?: (event: MouseEvent) => void;
  children: ReactNode;
  color: ButtonColor;
}

export const Button = ({ onClick, children, color }: ButtonProps) => (
  <button
    className={classNames({ [`button-${color}`]: color }, 'button')}
    onClick={onClick}
  >
    {children}
  </button>
);
