import { ReactNode, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: ReactNode;
  id: string | number;
}

export const DefaultPortalElementId = 'defaultPortalPosition';

/**
 * Portal for loading components into nodes that might be rendered concurrently
 * with the portal itselt. It waits until it is "rendered" before trying to
 * attach its children
 *
 */
export const Portal = ({ children, id }: PortalProps) => {
  const [component, setComponent] = useState<JSX.Element | null>(null);

  useLayoutEffect(() => {
    const parentElement =
      document.querySelector(`div.ihned_object[o_id="${id}"]`) ||
      document.querySelector(`#${DefaultPortalElementId}`);

    if (parentElement) {
      setComponent(ReactDOM.createPortal(children, parentElement));
    } else {
      console.error(`Unable to find a parent node with id ${id}`);
    }
  }, [id, children]);
  return component;
};
