import * as R from 'ramda';
import React, { FC } from 'react';

import { Portal, DefaultPortalElementId } from './Portal';
import { ArticleModuleTypes } from '../constants';
import { Gallery } from './components/gallery/Gallery';
import { Infographics } from './components/infographics/Infographics';
import { Iobject } from '../types';
import { Video } from './components/video/Video';

interface ModuleComponentProps {
  data: any;
}

const getModuleComponent = R.cond<ArticleModuleTypes, FC<ModuleComponentProps>>(
  [
    [
      R.equals<ArticleModuleTypes>(ArticleModuleTypes.gallery),
      R.always(Gallery),
    ],
    [
      R.equals<ArticleModuleTypes>(ArticleModuleTypes.info_graphic),
      R.always(Infographics),
    ],
    [
      R.equals<ArticleModuleTypes>(ArticleModuleTypes.embedded_video),
      R.always(Video),
    ],
  ]
);

export const makeIobjectPortals = (iobjects: Iobject[]) => (
  <>
    <div id={DefaultPortalElementId} />
    {R.map<Iobject, JSX.Element>((iobject) => {
      const ModuleComponent = getModuleComponent(iobject.type);

      return (
        <Portal id={iobject.id} key={iobject.id}>
          <ModuleComponent data={iobject} />
        </Portal>
      );
    }, iobjects)}
  </>
);
