import React, { FC } from 'react';

import style from './Divider.module.scss';

interface DividerProps {
  title: string;
}

export const Divider: FC<DividerProps> = ({ title, children }) => {
  return (
    <div className={style.divider}>
      <div className={style.dividerTitle}>{title}</div>
      {children}
    </div>
  );
};
