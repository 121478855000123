import React from 'react';
import { ReactComponent as LogoBlack } from '../../assets/images/logo-HN-black.svg';

import style from './Footer.module.scss';

export const Footer = () => {

  return(
    <div className={style.footer}>
      <div className="containerCenter">
        <div className={style.container}>
          <div className={style.footerLogoBox}>
            <LogoBlack className={style.footerLogo} />
            <p>Copyright © 1996-2020 Economia, a.s.,</p>
            <p>Hospodářské Noviny IHNED ISSN 1213-7693</p>
          </div>
          <div className={style.footerBox}>
            <ul className={style.footerLinks}>
              <li className={style.footerLinksTitle}>
                <a href="https://www.economia.cz/contact/">Kontakty</a> /{' '}
                <a href="https://ihned.cz/c1-66475760-tiraz-hn">Tiráž</a>
              </li>
              <li>
                <a href="mailto:inzerce@ihned.cz">inzerce@ihned.cz</a>
              </li>
              <li>
                <a href="mailto:hn@economia.cz">hn@economia.cz</a>
              </li>
              <li>
                Infolinka{' '}
                <a href="tel:217 777 888" className={style.footerPhone}>
                  217 777 888
                </a>
              </li>
            </ul>
          </div>
          <div className={style.footerBox}>
            <ul className={style.footerLinks}>
              <li>
                <a href="https://www.economia.cz/ceniky-inzerce/">
                  Informace o inzerci
                </a>
              </li>
              <li>
                <a href="https://www.economia.cz/prace-u-nas/">Kariéra</a>
              </li>
              <li>
                <a href="https://ihned.cz/news/">Newslettery</a>
              </li>
              <li>
                <a href="https://www.economia.cz/vseobecne-podminky/">
                  Všeobecné podmínky
                </a>
              </li>
            </ul>
          </div>
          <div className={style.footerBox}>
            <ul className={style.footerLinks}>
              <li>
                <a href="https://www.economia.cz/prohlaseni-o-cookies/">
                  Prohlášení o cookies
                </a>
              </li>
              <li>
                <a href="https://www.economia.cz/ochrana-osobnich-udaju/?utm_source=ihned.cz">
                  Ochrana osobních údajů
                </a>
              </li>
              <li>
                <a href="https://www.economia.cz/audiovizualni-medialni-sluzby/">
                  Audiovizuální mediální služby
                </a>
              </li>
              <li>
                <a href="https://predplatne.ihned.cz/hn-plus?utm_source=ihned&amp;utm_medium=odkaz-zapati&amp;utm_term=economia-weby&amp;utm_campaign=predplatne-hn">
                  E-shop
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
};
