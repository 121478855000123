/* eslint-disable indent */
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import * as R from 'ramda';

import { ArticlePageData } from '../types';
import { Layout } from '../components/layout/Layout';
import { formatDate, getReadTimeText, htmlDecode, removeTime } from '../utils';
import { withPageData } from '../withPageData';

import style from './Article.module.scss';
import { UrlParams, GA } from '../constants';

import { useViewportSize } from '../hooks/useViewportSize';

import { LoginBlock } from '../components/loginBlock/LoginBlock';
import { ArticleSlider } from '../components/slider/ArticleSlider';
import { ArticlePageArticleCard } from '../components/articleCard/ArticlePageArticleCard';
import { Screens, ArticleModuleTypes } from '../constants';
import { ShareArticle } from '../components/shareArticle/ShareArticle';
import { UserContext } from '../UserContext';
import { ArticleBody } from '../components/articleBody/ArticleBody';
import { publishArtEvent } from '../lib/exponea';
import { VideoDescription } from '../components/videoDescription/VideoDescription';

interface ArticleContentProps {
  article: ArticlePageData;
}

let enableCall = true;

const CardMargin = 15;
const ArticleMaxWidth = 700;
const maxContainerWidth = 1440;

const getIntialOffset = (viewportWidth: number) => {
  const containerWidth = Math.min(viewportWidth, maxContainerWidth);

  return (containerWidth - ArticleMaxWidth) / 2 - CardMargin;
};

const getIsVideoArticle = (
  iobjects: ArticlePageData['iobjects'],
  articleBody: string
) => {
  if (!iobjects) {
    return false;
  }

  const iobjectsValues = R.values(iobjects);

  if (
    iobjectsValues.length === 1 &&
    iobjectsValues[0].type === ArticleModuleTypes.embedded_video
  ) {
    const article = document.createElement('div');
    article.innerHTML = articleBody;
    const anchors = iobjectsValues.map((iobject) =>
      article.querySelector(`div.ihned_object[o_id="${iobject.id}"]`)
    );
    const validAnchors = R.reject(R.isNil, anchors);

    return validAnchors.length === 0;
  }
  return false;
};

interface ArticlePageProps {
  pageData: ArticlePageData;
}

export const ArticlePage = ({ pageData: article }: ArticlePageProps) => {
  const { viewportWidth } = useViewportSize();

  const [isStickyHidden, setIsStickyHidden] = useState(false);

  const continueReadingRef = useRef<HTMLDivElement>(null);
  const stickyBlockRef = useRef<HTMLDivElement>(null);

  /**
   * Hide sticky block if it intersects with the bottom position of the heading.
   * The function is throttled
   */
  const hideSticky = useCallback(() => {
    if (!enableCall) return;

    enableCall = false;

    setTimeout(() => {
      const headingBottomPosition = continueReadingRef.current?.getBoundingClientRect()
        .bottom;

      const stickyBottomPosition = stickyBlockRef.current?.getBoundingClientRect()
        .bottom;

      setIsStickyHidden(
        !!(
          headingBottomPosition &&
          stickyBottomPosition &&
          headingBottomPosition < stickyBottomPosition
        )
      );

      enableCall = true;
    }, 100);
  }, []);

  const userInfo = useContext(UserContext);

  useEffect(() => {
    const { title, id, published, tags, authors, perex } = article;
    const author = authors[0];
    publishArtEvent(
      {
        title,
        author,
        id: id.toString(),
        tags: Object.values(tags),
        description: perex,
        ['published time']: removeTime(published),
      },
      userInfo?.activeSubscription
    );
  }, [article, userInfo]);

  useEffect(() => {
    hideSticky();
    document.body.addEventListener('scroll', hideSticky);
    return () => {
      document.body.removeEventListener('scroll', hideSticky);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isVideoArticle = useMemo(
    () => article.iobjects && getIsVideoArticle(article.iobjects, article.body),
    [article]
  );

  return (
    <Layout
      breadcrumbs={{
        theme: { title: article.theme.title, link: `/${article.theme.slug}` },
        article: { title: article.title, link: '#' },
      }}
    >
      <Helmet>
        <meta property="og:type" content="article" />
        <meta property="og:title" content={article.title} />
        {/* // TODO: chage to perex once it is available from API */}
        <meta property="og:description" content={article.body.substr(0, 100)} />
        {/* // TODO: enable image once it is available from API */}
        {/* <meta
          property="og:image"
          content={article.image}
        /> */}
      </Helmet>
      <div className={style.containerArticle}>
        <div className={style.container}>
          <div
            className={classNames(
              style.sticky,
              isStickyHidden && style['sticky--hidden']
            )}
          >
            <div className={style.fixedBlock} ref={stickyBlockRef}>
              <div className={style.fixedBlockTitle}>
                <Link
                  to={`/${article.theme.slug}`}
                  onClick={() => {
                    ReactGA.event({
                      category: GA.categories.backToTopic,
                      action: `/${article.theme.slug}/${article.id}`,
                      label: article.theme.slug,
                    });
                  }}
                >
                  {article.theme.title}
                </Link>
              </div>
              {htmlDecode(article.theme.perex)}
              <ShareArticle title={article.title} />
            </div>
          </div>
          <h1 className={style.articleTitle}>{article && article.title}</h1>
          {/*{TODO Create component}*/}
          <div>
            <span className={style.subtitle}>
              Článek <span className={style.dot}></span>
              {getReadTimeText(article.readingTime)} čtení
            </span>
            <span className={style.subtitlePublished}>
              {formatDate(article.published)}
            </span>
          </div>
          <div
            className={classNames(
              'contentArticle',
              !userInfo?.activeSubscription && style.articleHidden
            )}
          >
            <ArticleBody
              articleBody={article.body}
              iobjects={article.iobjects}
            />
            {isVideoArticle ? (
              <VideoDescription
                perex={article.perex}
                tags={article.tags}
                authors={article.authors}
                published={article.published}
              />
            ) : (
              <span className={style.square}></span>
            )}
            <div className={style.contentFooter}>
              <ShareArticle />
            </div>
          </div>
          {!userInfo?.activeSubscription && <LoginBlock />}
        </div>
        <div className={style.containerSlider}>
          {viewportWidth > Screens.md && (
            <div ref={continueReadingRef}>
              <h3 className={classNames(style.titleContinue)}>
                Pokračovat ve čtení
              </h3>
            </div>
          )}
          {viewportWidth > Screens.sm && viewportWidth >= Screens.lg ? (
            <div className={style.sliderWrap}>
              <ArticleSlider
                translate={getIntialOffset(viewportWidth)}
                items={article.theme.otherArticles.map((otherArticle) => ({
                  article: otherArticle,
                  themeSlug: article.theme.slug,
                }))}
                renderItem={ArticlePageArticleCard}
                itemProps={{
                  gaEventData: {
                    category: GA.categories.article,
                    action: `/${article.theme.slug}/${article.id}`,
                  },
                }}
              />
            </div>
          ) : (
            <div className="containerCards">
              <div className="cards">
                {article.theme.otherArticles.map((otherArticle) => (
                  <ArticlePageArticleCard
                    key={otherArticle.id}
                    item={{
                      article: otherArticle,
                      themeSlug: article.theme.slug,
                    }}
                    gaEventData={{
                      category: GA.categories.article,
                      action: `/${article.theme.slug}/${article.id}`,
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export const Article = withPageData<ArticlePageData>(
  ArticlePage,
  `theme/:${UrlParams.ThemeSlug}/article/:${UrlParams.ArticleId}`
);
