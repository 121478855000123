import React from 'react';
import { IobjectVideo } from '../../../types';

interface VideoProps {
  data: IobjectVideo;
}

export const Video = ({ data }: VideoProps) => {
  return <div dangerouslySetInnerHTML={{ __html: data.embed }} />;
};
